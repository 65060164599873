<script>
    import Navigation from "./Navigation.svelte";
    import Header from "./Header.svelte";
    import Footer from "./Footer.svelte";

    export let pageId, headline, footerText = "";


    const mainLinks = [
        {
            label: "Hippotherapie",
            path: "hippotherapie"
        },
        {
            label: "Indikationen",
            path: "indikationen"
        },
        {
            label: "Die Therapie",
            path: "therapie"
        },
        {
            label: "Über mich",
            path: "therapeutin"
        },
        {
            label: "Die Pferde",
            path: "pferde"
        },
        {
            label: "Adresse",
            path: "adresse"
        },
        {
            label: "Nützliche Links",
            path: "links"
        },
    ];

    const pferdeosteopathieLinks = [
        {
            label: "Pferdeosteopathie",
            path: "pferdeosteopathie",
        },
    ];

    const otherLinks = [
        {
            label: "Impressum",
            path: "impressum"
        },
    ];

    const linkGroups = [
        mainLinks,
        pferdeosteopathieLinks,
        otherLinks,
    ]
</script>

<Header {linkGroups} currentPage={pageId} />

<div class="stage">
    <Navigation {linkGroups} currentPage={pageId} />
    <main>
        <h1>{headline}</h1>
        <slot name="content-body" />
    </main>
</div>

<Footer text={footerText} />
