<script>
    import { slide } from 'svelte/transition';
    export let currentPage, linkGroups;
    let open = false;

    function getLinkClass(navItem) {
        const baseClass = navItem.path + '-nav' || '';
        return currentPage === navItem.path ? 'active' : baseClass;
    }

    function handleButtonClick() {
        open = !open;
    }

    function navigate(path) {
        return () => {
            window.location.hash = path;
        };
    }
</script>

{#if open}
    <div class="wrapper" transition:slide>
        <div class="header">
            <button class="close" on:click={handleButtonClick}></button>
        </div>
        <nav on:click={handleButtonClick}>
            {#each linkGroups as linkGroup}
                <ul>
                    {#each linkGroup as link}
                    <li class="{getLinkClass(link)}" on:click={navigate(link.path)}>{link.label}</li>
                    {/each}
                </ul>
            {/each}
        </nav>
    </div>
{:else}
    <button class="menu" on:click={handleButtonClick}></button>
{/if}

<style>
    .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background: #999966;
        width: 100%;
        padding: 0.5rem;
    }
    button {
        cursor: pointer;
        background-size: 1.2rem;
        background-repeat: no-repeat;
        background-color: transparent;
        border: 0;
        width: 1.2rem;
        height: 1.2rem;
        padding: 0;
    }

    button.menu {
        margin-top: 1.8rem;
        background-image: url("../imgs/mobilenavigation_burger-menu.svg");
    }

    .header {
        text-align: right;
        padding: 0.5rem;
    }

    button.close {
        margin: 0.2rem 0.4rem 0 0;
        background-image: url("../imgs/mobilenavigation_close.svg");
    }

    ul {
        list-style-type: none;
        margin: 1.4rem 0 0 0;
        padding: 0;
    }

    li {
        padding: 0.7rem 0 0.4rem 0.5rem;
        font-family: Exo-MediumItalic, Helvetica,Verdana,sans-serif;
        font-weight: normal;
        font-size: 0.8rem;
        border-bottom: 1px solid white;
        letter-spacing: 0.1em;
        list-style-type: none;
        cursor: pointer;
    }

    li a,a:visited,a:active {
        text-decoration: none;
        color: white;
    }

    li.active {
        background: #cccc33;
    }

    li.active a {
        color: black;
    }

    li:hover {
        font-weight: bold;
        background-color: #47512a;
    }

    .pferdeosteopathie-nav {
        background: #AAAA8D;
    }

    @media screen and (min-width: 72.01rem) {
        .wrapper {
            display: none;
        }

        button.menu {
            display: none;
        }
    }
</style>
