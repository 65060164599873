<svelte:window on:hashchange={updateRoute}/>

<div class="wrapper">
    <svelte:component this={currentComponent} />
</div>

<script>
    import {onMount} from 'svelte';
    import Hippotherapie from "./Hippotherapie.svelte";
    import Indikationen from "./Indikationen.svelte";
    import Impressum from "./Impressum.svelte";
    import Links from "./Links.svelte";
    import Pferdeosteopathie from "./Pferdeosteopathie.svelte";
    import Therapeutin from "./Therapeutin.svelte";
    import Pferde from "./Pferde.svelte";
    import Therapie from "./Therapie.svelte";
    import Adresse from "./Adresse.svelte";

    const routeMapping = {
        'default': Hippotherapie,
        'hippotherapie': Hippotherapie,
        'indikationen': Indikationen,
        'therapie': Therapie,
        'therapeutin': Therapeutin,
        'pferde': Pferde,
        'adresse': Adresse,
        'links': Links,
        'pferdeosteopathie': Pferdeosteopathie,
        'impressum': Impressum,
    };

    function updateRoute() {
        const route = window.location.hash.slice(1);
        currentComponent = routeMapping[route in routeMapping ? route : 'default'];
    }

    let currentComponent = Hippotherapie;

    onMount(updateRoute);
</script>
