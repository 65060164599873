<script>
    export let name, imgSrc;
</script>

<div class="team-member">
    <div class="content-wrapper">
        <h2>{name}</h2>
        <slot />
        <img src="{imgSrc}" alt="{name}" class="team-member-image">
    </div>
</div>

<style>
    h2 {
        margin-bottom: 1em;
    }
    .team-member {
        flex: 1 1 420px;
        display: flex;
        flex-direction: column;
        background: #999966;
        padding: 15px;
    }

    .content-wrapper {
        margin: 0;
    }
    .team-member-image {
        max-width: 100%;
        height: auto;
        margin-bottom: 0;
    }
</style>
