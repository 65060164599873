<script>
    import MobileNavigation from "./MobileNavigation.svelte";
    export let currentPage, linkGroups;
</script>

<header>
    <div class="logo">
        <h1>Therapeutisches Reiten<br>Hippotherapie</h1>
        <h2>Freia Kolozei</h2>
    </div>
    <MobileNavigation {currentPage} {linkGroups} />
</header>

<style>
    header {
        display: flex;
        margin: 0 0 2rem 0;
        padding: 1.8rem 1.6rem 0.2rem 1.6rem;
        background: no-repeat 23.5rem 2.8rem / 20rem url("../imgs/header_schweif_874.png"), linear-gradient(white 0%, #cccc33 100%);
        box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,.5);
    }

    .logo {
        flex: 1 1 auto;
        margin: 1rem 0 0.5rem 0;
    }

    .logo h1 {
        font: normal 1.9rem/110% Exo-ThinItalic, sans-serif;
        color: #000000;
        margin: 0;
    }

    .logo h2 {
        margin: 0.4rem 0 0 0;
    }

    /* medium viewport */
    @media screen and (max-width: 72rem) {
        header {
            padding: 0.4rem 1.6rem 0 1.6rem;
            background:
                    no-repeat 17.5rem 2.0rem / 10rem url("../imgs/header_schweif_874.png"),
                    linear-gradient(white 0%, #cccc33 100%);
        }
        .logo h1 {
            font-size: 1.3em;
            font-weight: bold;
            padding: 0.3rem 0 0 0;
        }
        .logo h2 {
            font-size: 1rem;
        }
    }

    /* very small viewport */
    @media screen and (max-width: 500px) {
        header {
            padding: 0.4rem 1.6rem 0 1.6rem;
            background:
                    no-repeat 12rem 4.0rem / 9rem url("../imgs/header_schweif_874.png"),
                    linear-gradient(white 0%, #cccc33 100%);
        }

    }
</style>
