<script>
    export let currentPage, linkGroups;

    function getLinkClass(navItem) {
        const baseClass = navItem.path + '-nav' || '';
        return currentPage === navItem.path ? 'active' : baseClass;
    }

    function navigate(path) {
        return () => {
            window.location.hash = path;
        };
    }
</script>

<nav>
    {#each linkGroups as linkGroup}
        <ul>
            {#each linkGroup as link}
            <li class="{getLinkClass(link)}" on:click={navigate(link.path)}>{link.label}</li>
            {/each}
        </ul>
    {/each}
</nav>

<style>
    nav {
        flex: 0 0 12rem;
        margin-right: 1.2rem;
    }

    /* medium viewport */
    @media screen and (max-width: 72rem) {
        nav {
            display: none;
        }
    }

    ul {
        margin: 0 0 1.9rem 0;
        list-style-type: none;
        padding: 0;
        width: 11rem;
    }

    li {
        background: #999966;
        border-radius: 0.3rem;
        box-shadow: 0 0.5rem 1.5rem rgba(0,0,0,.5);
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        margin-bottom: 0.5rem;
        font-family: Exo-MediumItalic, Helvetica,Verdana,sans-serif;
        font-weight: normal;
        font-size: 0.8rem;
        text-align: center;
        letter-spacing: 0.1em;
        list-style-type: none;
        cursor: pointer;
    }

    li.active {
        background: #cccc33;
        color: black;
    }

    li:hover {
        font-weight: bold;
    }

    .pferdeosteopathie-nav {
        background: #AAAA8D;
    }
</style>