<script>
    export let text;
</script>

<footer>
    <span>{text}</span>
</footer>

<style>
    footer {
        background: linear-gradient(white 0%, #cccc33 100%);
        box-shadow: 0 -0.5rem 1.5rem rgba(0,0,0,.5);
        bottom: 0;
        font-family: Exo-ThinItalic, sans-serif;
        font-weight: bolder;
        font-variant: small-caps;
        font-size: 1.2rem;
        margin: 1rem 0 0 0;
        padding: 2rem 1.6rem 0.6rem 1.6rem;
        letter-spacing: 0.05em;
        line-height: 100%;
        color: #000000;
    }
    /* medium viewport */
    @media screen and (max-width: 72rem) {
        footer {
            font-size: 1.0em;
        }
    }
</style>